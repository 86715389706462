<template>
  <div>
    <breadcrumb :items="items"/>
    <p>{{ $t('app.configuration') }}</p>
  </div>
</template>

<script>
export default {
  components: {
    Breadcrumb: () => import('@/components/Breadcrumb.vue'),
  },
  data() {
    return {
      items: [
        {
          text: this.$t('app.dashboard'),
          disabled: true,
          href: 'dashboard',
        },
        {
          text: this.$t('app.configuration'),
          disabled: false,
          href: 'configuration',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/views/_configuration.scss';
</style>
